import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import copy from 'clipboard-copy';
import Web3 from 'web3';
import { toast } from 'react-toastify';
import bigDecimal from "js-big-decimal";
import { buyWithB, buyWithU, getDataSM, dataWeb, submitApproveStaking, checkApproveStaking, getInfoStaking } from "../../services/smartContract";
import { checkStatusW, subWithdrawCoin } from "../../services/smartContract";
import { checkUser } from "../../services/userService";
import { abi_staking, contractStaking, abi_usdt, contractUsdt, contractBNB, abi_bnb } from '../../setup/USDT';
import { convertNumber } from "../../setup/fc";

function Aff(props) {
    const bigUnit = new bigDecimal("1000000000000000000");
    const navigate = useNavigate();
    const [addr, setAddr] = useState(0);
    const [userId, setUserId] = useState(0);
    const [loading, setLoading] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [total, setTotal] = useState(0);


    useEffect(() => {
        fc_start();
    }, []);

    const fc_start = async () => {
        checkConnect();
    }


    const checkConnect = async () => {
        if (typeof window.ethereum !== 'undefined') {
            try {
                const web3 = new Web3(window.ethereum);
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                const accounts = await web3.eth.getAccounts();
                const userAddress = accounts[0];
                let data = await checkUser(userAddress);
                if (data && data.status == 999) {
                    setUserId(data.userId);                    
                    let getTransactionHistory;
                    getTransactionHistory = await getInfoStaking();

                    if (getTransactionHistory) {
                        let arr = [];

                        for (let index = 0; index < getTransactionHistory.length; index++) {
                            const element = getTransactionHistory[index];
                            arr.push(element);
                        }

                        let sortedTransactions = arr.sort((a, b) => Number(b.timestamp) - Number(a.timestamp));

                        setTransactions(sortedTransactions);
                        console.log(sortedTransactions)
                    }

                } else {
                    toast.error('You do not have sufficient permissions.');
                     navigate("/");
                }

            } catch (error) {
                console.log(error);
                toast.error('You do not have sufficient permissions.');
                navigate("/");
            }
        } else {
            toast.error('You do not have sufficient permissions.');

        }
    }

    const copyLink = () => {
        copy('https://web3.megabit.is/?aff=' + userId);
        toast.success("The link has been copied");
    }

    const formattedPrice = (value) => {
        return Math.round(value * 1000) / 1000;
    };
    const formatNumber = (number, decimalPlaces = 6) => {
        if (number === null || number === undefined) {
            return number;
        }

        // Chuyển BigInt về kiểu Number nếu cần.
        const numericValue = Number(number);

        // Nếu không phải là một số hợp lệ, trả về null.
        if (isNaN(numericValue)) {
            return null;
        }

     
        const roundedNumber = numericValue.toFixed(decimalPlaces);

        const [integerPart, decimalPart] = roundedNumber.split('.');

        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
    };

    const formatTimestamp = (date) => {
        const formattedDate = new Date(date);
        const year = formattedDate.getUTCFullYear();
        const month = String(formattedDate.getUTCMonth() + 1).padStart(2, '0');
        const day = String(formattedDate.getUTCDate()).padStart(2, '0');
        const hours = String(formattedDate.getUTCHours()).padStart(2, '0');
        const minutes = String(formattedDate.getUTCMinutes()).padStart(2, '0');

        return `${year}.${month}.${day} ${hours}:${minutes}`;
    };

    useEffect(() => {
        let totalAmount = 0;     
       
        transactions.forEach((transaction) => {
          if (Number(userId) === Number(transaction?.aff)) {
            totalAmount += (Number(transaction?.amount) / 1e18) * 0.2;
          }
        });     
       
        setTotal(totalAmount);
      }, [transactions, userId]); 

      const clickHome = () => {        
        navigate("https://web3.megabit.is");
       
    }
    return (
        <>

            <div id="sc_presale" className='sc_aff'>
                <h1 onClick={() => clickHome()}>Megabit</h1>
                <div className="box-presale">
                    <h2>Marketing Dashboard</h2>
                    <div>
                        <h5>Your Referral Link</h5>
                        <p>https://web3.megabit.is/?aff={userId} <button type='button' className='btn-copy' onClick={() => copyLink()}>Copy</button></p>
                    </div>
                    <hr></hr>
                    <div>
                        <h5>Commission List ({total})</h5>
                        <div className='box-transaction'>
                        <table className="transaction-table">
                            <thead>
                                <tr>
                                    <th>Amount</th>
                                    <th>User Address</th>
                                    <th className='text-right'>Timestamp</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactions.map((transaction, index) => {
                                    // Kiểm tra điều kiện userId === transaction?.aff
                                    if (Number(userId) === Number(transaction?.aff)) {
                                        return (
                                            <tr key={index}>
                                                <td>{ ((Number(transaction?.amount) / 1e18) * 0.2) }</td>                                               
                                                <td>{transaction?.userAddress.slice(0, 4)}...{transaction?.userAddress.slice(-6)}</td>
                                                <td className='text-right'>{formatTimestamp(Number(transaction?.timestamp) * 1000)}</td>
                                            </tr>
                                        );
                                    }
                                    // Nếu điều kiện không thỏa mãn, không render gì
                                    return null;
                                })}
                            </tbody>


                        </table>
                        </div>
                    </div>
                    <div>
                        <div className="text-center link-footer link-footer-aff"><a target='_blank' href="https://megabit.is">Megabit.is</a></div>
                    </div>

                </div>
            </div>

        </>
    );
}

export default Aff;