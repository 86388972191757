import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    BrowserRouter as Router,
    Route,
    Routes
} from "react-router-dom";
import NotFound from '../componets/Layout/NotFound';
import Home from '../componets/Home/Home';
import Affiliate from '../componets/Home/Affiliate';
function AppRoutes(props) {
   
    return (
        <>
            <Routes>
                <Route path="/affiliate" element={<Affiliate />} />                            
                <Route path="/" element={<Home />} />
                <Route path="*" element={<NotFound />} />
            </Routes>

        </>
    ); 
    
}

export default AppRoutes;