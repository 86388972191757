import React from 'react';
import About from './About';
import Aff from './Aff';
import ParticlesComponent from "./ParticlesComponent";
import './home.css';
function Affiliate(props) {
  return (
    <>
   
      <div className='page-home animated-bg'>      
        <ParticlesComponent />
        <Aff />       
      </div>

    </>
  );
}

export default Affiliate;